<template>
  <div class="container-fluid main-color">
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title" v-if="currentRouteName == 'hrd-ojt-assessment-getEditId'">Edit Record</h4>
          <h4 class="page-title" v-else>Add Record</h4>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <place-holder v-if="loading"></place-holder>
        <div class="card">
          <div v-if="!loading" class="card-body">
            <form class="parsley-examples">
              <div class="row">
                <div class="col-md-7">
                  <div class="row mb-3">
                    <label for="name" class="col-md-3 form-label">
                      Program Name
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                      <input
                        type="text"
                        class="form-control"
                        v-model="assessments[0].assessment.program_name"
                        disabled
                      />
                    </div>
                  </div>

                  <div class="row mb-3">
                    <label for="short_name" class="col-md-3 form-label">
                      Employee Name
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                      <input
                        type="text"
                        class="form-control"
                        v-model="assessments[0].employee_name"
                        disabled
                      />
                    </div>
                  </div>

                  <div class="row mb-2">
                    <label for="short_name" class="col-md-3 form-label">
                      Employee ID
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                      <input
                        type="text"
                        v-model="assessments[0].employee_id"
                        class="form-control"
                        disabled
                      />
                    </div>
                  </div>

                  <div class="row mb-2">
                    <label for="short_name" class="col-md-3 form-label">
                      Level
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                      <input
                        type="text"
                        v-model="assessments[0].assessment.level"
                        class="form-control"
                        disabled
                      />
                    </div>
                  </div>
                </div>

                <div class="row col-md-5">
                  <div class="row mb-3">
                    <label for="short_name" class="col-md-3 form-label">
                      Batch
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                      <input
                        type="text"
                        class="form-control"
                        v-model="assessments[0].assessment.program_batch_no"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label for="date" class="col-md-3 form-label">
                      Joined Date
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                      <input
                        type="text"
                        class="form-control"
                        v-model="assessments[0].assessment.join_date"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label
                      for="evaluating_criteria"
                      class="col-md-3 form-label"
                    >
                      Choose Weight
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                      <v-select
                        @option:selected="getSelectedCriteria($event)"
                        label="name"
                        :options="criteriaWeights"
                        :reduce="(weight) => weight.name"
                        :clearable="false"
                        v-model="selectedWeight"
                        :class="{
                          'p-invalid':
                            v$.selectedWeight.$error ||
                            errorFor('Criteria Weight'),
                        }"
                      >
                      </v-select>
                      <v-errors
                        :serverErrors="errorFor('Criteria Weight')"
                        :vuelidateErrors="{
                          errors: v$.selectedWeight.$errors,
                          value: 'Criteria Weight',
                        }"
                      ></v-errors>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="progress mt-3"
                style="height: 0.5px; background-color: #ccc !important"
              >
                <div class="progress-bar" role="progressbar"></div>
              </div>

              <div class="row g-3 mt-3">
                <div class="col-sm-4">
                  <h4 class="mb-3">Criteria</h4>
                </div>
                <div class="col-sm-4">
                  <h4 class="mb-3">Scores:*</h4>
                </div>
                <div class="col-sm-4">
                  <h4 class="mb-3">Remark:</h4>
                </div>
              </div>

              <div
                class="row g-3"
                v-for="(cri, index) in criteria"
                :key="index"
              >
                <div class="col-sm-4">
                  <p class="mb-3 pt-2 pl-2">{{ cri.name }}</p>
                </div>
                <div class="col-sm-4">
                  <div class="mt-2">
                    <v-select
                      class="w-100 selectScore"
                      v-model="cri.selectedScore"
                      :options="scoreOptions(cri)"
                      :clearable="false"
                      @option:selected="updateTotalScore"
                    ></v-select>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="mt-2">
                    <input
                      type="text"
                      class="form-control mt-2"
                      placeholder="Zip"
                      aria-label="Zip"
                      v-model="cri.remark"
                    />
                  </div>
                </div>
              </div>
              <div class="row g-3">
                <div class="col-sm-4">Total Score</div>
                <div class="col-sm-4">
                  <input
                    type="text"
                    class="form-control mt-2"
                    v-model="total_score"
                    disabled
                  />
                </div>
              </div>

              <div class="row g-3">
                <div class="col-sm-4">Weight</div>
                <div class="col-sm-4">
                  <input
                    type="text"
                    class="form-control mt-2"
                    v-model="weight"
                    disabled
                  />
                </div>
              </div>

              <!-- end row -->
              <div class="row">
                <div class="col-md-12">
                  <div class="text-center mt-3 mb-3">
                    <router-link :to="{ name: 'hrd-ojt-assessment' }">
                      <button type="button" class="btn w-sm btn-secondary me-5">
                        Cancel
                      </button>
                    </router-link>
                    <button
                      :disabled="isLoading ? true : false"
                      type="button"
                      class="btn w-sm btn-success waves-effect waves-light px-3"
                      @click="saveOjtAssessment()"
                    >
                      <span
                        v-if="isLoading"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      {{ isLoading == true ? "Loading..." : "Save" }}
                    </button>
                  </div>
                </div>
                <!-- end col -->
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import useVuelidate from "@vuelidate/core";
import { useToast } from "vue-toastification";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { required } from "@vuelidate/validators";
import validationErrors from "../../../mixins/validationErrors";

export default {
  setup() {
    const toast = useToast();
    return { v$: useVuelidate(), toast };
  },
  components: {
    vSelect,
  },
  mixins: [validationErrors],
  data() {
    return {
      total_score: 0,
      weight: 0,
      selectedScores: [],
      assessments: {},
      criterias: [],
      criteria: [],
      criteriaWeights: [],
      selectedWeight: null,
      evaluatingCriteriaId: null,
      baseUrl: process.env.VUE_APP_BASE_URL,
      baseUrlHRIS: process.env.VUE_APP_COURSIA_BASE_URL,
      loading: false,
      isLoading: false,
    };
  },
  validations() {
    return {
        selectedWeight: { required },
    };
  },
  methods: {
    async getAssessmentData() {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(
          `${this.baseUrl}admin/v1/hrd-ojt-assessment/${this.$route.params.id}`
        )
        .then((response) => {
          this.assessments = response.data.data;
          this.getAllCriteria();
        })
        .catch((error) => {
          if(error.response.status === 404) {
            this.toast.error('Page not found.');
            this.$router.push('page-not-found');
          }else {
          this.errors = error.response;
          this.toast.error("Not Criteria Found!");
          this.isLoading = false;
          }
        })
    },
    async getAllCriteria() {
      await axios
        .get(
          `${this.baseUrl}admin/v1/hrd-ojt-assessment/${this.assessments[0].assessment.program_id}/${this.assessments[0].assessment.level}/OJT`
        )
        .then((response) => {
          let data = response.data.criteria;
          console.log(data)
          for (let i = 0; i < data.length; i++) {

            this.criteriaWeights.push({
              id: data[i].id,
              evaluating_criteria_id: data[i].evaluating_criteria_id,
              name: data[i].criteria_name,
              weight: data[i].weight,
            });
            if (data[i].evaluating_criteria_id == this.evaluatingCriteriaId) {
              this.selectedWeight = data[i].criteria_name;
            }

            let hrdOjt = data[i].hrd_ojt_criteria;

            for (let j = 0; j < hrdOjt.length; j++) {
              this.criterias.push({
                id: hrdOjt[j].id,
                name: hrdOjt[j].name,
                evaluating_criteria_id: hrdOjt[j].evaluating_criteria_id,
                lowest_score: hrdOjt[j].lowest_score,
                highest_score: hrdOjt[j].highest_score,
                remark: null,
                selectedScore: hrdOjt[j].lowest_score,
              });
            }
            // this.weight = response.data.criteria.weight;
          }

          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          if(error.response.status === 404) {
            this.toast.error('Page not found.');
            this.$router.push('page-not-found');
          }else {
          this.errors = error.response;
          this.toast.error("Something Went Wrong!");
          this.isLoading = false;
          }
        })
    },
    async saveOjtAssessment() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;

      let toSendData = {
        ojtCriteria: this.criteria,
        totalScore: this.total_score,
        statusAssessment: this.assessments[0].status,
        weight: this.weight,
        level: this.assessments[0].assessment.level,
      };

      let id = this.$route.params.hrdId
        ? this.$route.params.hrdId
        : this.$route.params.id;
      await axios
        .post(`${this.baseUrl}admin/v1/hrd-ojt-assessment/${id}`, toSendData, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(() => {
          this.$router.push({ name: "hrd-ojt-assessment" });
          this.toast.success("Successfully Saved OJT Assessment!");
          this.isLoading = false;
        })
        .catch((error) => {
          if(error.response.status === 404) {
            this.toast.error('Page not found.');
            this.$router.push('page-not-found');
          }else {
          this.errors = error.response;
          this.toast.error("Something Went Wrong!");
          this.isLoading = false;
          }
        })
    },

    updateTotalScore() {
      let totalScore = 0;
      for (let i = 0; i < this.criteria.length; i++) {
        totalScore += this.criteria[i].selectedScore;
      }
      this.total_score = totalScore;
    },

    scoreOptions(cri) {
      const options = [];
      for (let i = cri.lowest_score; i <= cri.highest_score; i++) {
        options.push(i);
      }
      return options;
    },

    async getEditById() {
      this.loading = true;
      this.$Progress.start();
      let id = this.$route.params.hrdId
        ? this.$route.params.hrdId
        : this.$route.params.id;
      await axios
        .get(
          `${this.baseUrl}admin/v1/hrd-ojt-assessment-getEditId/${id}/1` //1 means completed state / status if(status == 0) to do state/
        )
        .then((response) => {
          // console.log(response.data)
          this.assessments = response.data.assessments;
          this.evaluatingCriteriaId = response.data.evaluatingCriteriaId;
          let data = response.data.ojtCriteria;

          data.forEach((ojt) => {
            this.criteria.push({
              id: ojt.id,
              name: ojt.name,
              lowest_score: ojt.lowest_score,
              highest_score: ojt.highest_score,
              status: ojt.status,
              remark: ojt.message,
              selectedScore: ojt.selectedScore,
            });
            this.total_score += Number(ojt.selectedScore);
            this.weight = response.data.criteria.weight;
            this.editCriteria = this.criteria;
          });
          this.assessments[0].assessment.level = response.data.criteria.level;

          this.getAllCriteria();
        })
        .catch((error) => {
          if(error.response && error.response.status === 404) {
            this.toast.error('Page not found.');
            this.$router.push('page-not-found');
          }else {
          this.errors = error.response;
          this.toast.error("Something Went Wrong!");
          this.isLoading = false;
          }
        })
        ;
    },

    getSelectedCriteria(val) {
      this.criteria = [];
      this.total_score = 0;

      if (val.evaluating_criteria_id == this.evaluatingCriteriaId) {
        this.criteria = this.editCriteria;
        this.total_score = this.criteria.reduce(
          (total, cri) => total + cri.selectedScore,
          0
        );
        return;
      }

      this.criterias.forEach((cri) => {
        if (val.evaluating_criteria_id == cri.evaluating_criteria_id) {
          this.criteria.push({
            id: cri.id,
            name: cri.name,
            evaluating_criteria_id: cri.evaluating_criteria_id,
            lowest_score: cri.lowest_score,
            highest_score: cri.highest_score,
            remark: null,
            selectedScore: cri.lowest_score,
          });
          this.total_score += Number(cri.lowest_score);
        }
      });
      this.weight = val.weight;
    },
  },

  created() {
    console.log(this.currentRouteName)
    if (this.currentRouteName == "hrd-ojt-assessment-getEditId") {
      this.getEditById();
    } else {
      this.getAssessmentData();
    }
  },

  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>

<style>
.custom-selectCSS {
  --vs-dropdown-min-width: 160px;
  --vs-dropdown-max-height: 200px;
}
.selectScore {
  padding: 0.45rem 0.9rem;
}
</style>
